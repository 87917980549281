'use strict';

import {
  REHYDRATE_CLIENT_SETTINGS,
  UPDATE_CLIENT_SETTING,
  REHYDRATE_GLOBAL_SETTINGS,
  UPDATE_GLOBAL_SETTING
} from 'actions';

export const rehydrateClientSettings = (client_settings) => ({
  type: REHYDRATE_CLIENT_SETTINGS,
  payload: client_settings
});

export const updateClientSetting = (chariot, key, value) => {
  return async (dispatch, getState) => {
    const old_settings = getState().settings.client_settings;

    dispatch({
      type: UPDATE_CLIENT_SETTING,
      key,
      payload: value
    });

    try {
      await chariot.updateUserSettings({
        client_settings: {
          [key]: value
        }
      });
    } catch (e) {
      dispatch(rehydrateClientSettings(old_settings));
    }
  };
};

export const rehydrateGlobalSettings = (global_settings) => ({
  type: REHYDRATE_GLOBAL_SETTINGS,
  payload: global_settings
});

export const updateGlobalSetting = (chariot, key, value) => {
  return async (dispatch, getState) => {
    const old_settings = getState().settings.global_settings;

    dispatch({
      type: UPDATE_GLOBAL_SETTING,
      key,
      payload: value
    });

    try {
      await chariot.updateUserSettings({
        global_settings: {
          [key]: value
        }
      });
    } catch(e) {
      dispatch(rehydrateGlobalSettings(old_settings));
    }
  };
};

