'use strict';

import React from 'react';
import RouteRoot from 'components/RouteRoot';
import RouteHeader from 'components/RouteHeader';
import RouteContainer from 'components/RouteContainer';
import { LoadingSpinner } from '@fsg/spokes';

import 'scss/utilities/loading-route.scss';

const LoadingRoute = () => {
  return (
    <RouteRoot className="loading-route">
      <RouteHeader hideClientSelector>
        Loading...
      </RouteHeader>
      <RouteContainer>
        <LoadingSpinner />
      </RouteContainer>
    </RouteRoot>
  );
};

export default LoadingRoute;
