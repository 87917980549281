'use strict';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChariotList, useChariot } from '@fsg/chariot.js/adaptors/react/hooks';
import { updateGlobalSetting } from 'actions/settings';
import { SelectInput } from '@fsg/spokes';

const ClientSwitcher = ({ disabled }) => {
  const dispatch = useDispatch(),
    chariot = useChariot();

  const [clients, error, loading] = useChariotList(`Client`, { getAsAdmin: true });
  const selectedClientID = useSelector((state) => state.settings.global_settings.gordon_client_id);

  useEffect(() => {
    (async () => {
      if (clients && selectedClientID) {
        const client = clients.find((client) => client._.id == selectedClientID);

        if (client) {
          chariot.loginState.client = client._;
          chariot.options.domain = `${ client._.domain }.fsgchariot.app`;
        }
      }
    })();
  }, [selectedClientID, JSON.stringify((clients || []).map((c) => c._))]);

  return (
    <div className="client-switcher">
      <SelectInput 
        disabled={ disabled }
        loading={ loading }
        className={ `white ${ error ? `invalid` : `` }` }
        value={ selectedClientID } 
        onChange={ (e) => dispatch(updateGlobalSetting(chariot, `gordon_client_id`, e.target.value)) }>
        {
          clients && clients.map((client) => (
            <option key={ client._.id } value={ client._.id }>{ client._.name }</option>
          ))
        }
      </SelectInput>
    </div>
  );
};

export default ClientSwitcher;
