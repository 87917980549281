'use strict';

import React from 'react';
import { useSelector  } from 'react-redux';
import { useChariotGet } from '@fsg/chariot.js/adaptors/react/hooks';
import AppNav from 'components/utilities/AppNav';
import LoadingRoute from 'components/utilities/LoadingRoute';
import { AppError, QuickAccess } from '@fsg/spokes';

import 'scss/utilities/authenticated-page-wrapper.scss';

const AuthenticatedPageWrapper = ({ children, hideNav }) => {
  const clientId = useSelector((state) => state.settings?.global_settings?.gordon_client_id);

  const [, error, loading] = useChariotGet(`Client`, clientId);

  if (loading) {
    return (
      <div className="authenticated-page-wrapper">
        <LoadingRoute />
      </div>
    );
  }

  if (error) {
    return (
      <div className="authenticated-page-wrapper">
        <AppError>{ error.message }</AppError>
      </div>
    );
  }

  return (
    <div className="authenticated-page-wrapper">
      { !hideNav && <AppNav /> }
      { children }
      <QuickAccess />
    </div>
  );
};

export default AuthenticatedPageWrapper;
