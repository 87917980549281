'use strict';

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { resetAuthState } from 'actions/auth';

import GordonLogo from 'svgs/Gordon';
import ClientsIcon from 'svgs/briefcase';
import SitesIcon from 'svgs/Sites';
import UsersIcon from 'svgs/Users';
import CameraIcon from 'svgs/camera';
import CodeIcon from 'svgs/code';
import LogoutIcon from 'svgs/Logout';
import HammerIcon from 'svgs/hammer';
import NiagaraIcon from 'svgs/niagara-falls';
import BotIcon from 'svgs/bot';
import VpamIcon from 'svgs/vpam';

import { QuickAction } from '@fsg/spokes';

import 'scss/utilities/app-nav.scss';

const AppNav = ({ location, history }) => {
  const dispatch = useDispatch();

  const NAVIGATION = [
    {
      key: `clients`,
      label: `Clients`,
      shortcut: `shift+alt+c`,
      path: `/clients`,
      icon: ClientsIcon
    },
    {
      key: `sites`,
      label: `Sites`,
      shortcut: `shift+alt+s`,
      path: `/sites`,
      icon: SitesIcon
    },
    {
      key: `users`,
      label: `Users`,
      shortcut: `shift+alt+u`,
      path: `/users`,
      icon: UsersIcon
    },
    {
      key: `service_accounts`,
      label: `Service Accounts`,
      path: `/service-accounts`,
      icon: BotIcon
    },
    {
      key: `scan`,
      label: `Scan Code`,
      shortcut: `shift+alt+r`,
      path: `/scan`,
      icon: CameraIcon
    },
    {
      key: `scripts`,
      label: `Scripts`,
      path: `/scripts`,
      icon: CodeIcon
    },
    {
      key: `templates`,
      label: `Templates`,
      path: `/templates`,
      icon: HammerIcon
    },
    {
      key: `niagara`,
      label: `Niagara`,
      path: `/niagara`,
      icon: NiagaraIcon
    },
    {
      key: `vpam`,
      label: `VPAM`,
      path: `/vpam`,
      icon: VpamIcon
    }
  ];

  const logout = useCallback(
    () => dispatch(resetAuthState()),
    [dispatch]
  );

  return (
    <aside className="app-nav">
      <nav>
        <Link className="logo" to="/">
          <span className="icon-wrapper"><GordonLogo width="30" /></span>
          <span className="label">Gordon</span>
        </Link>

        <ul>
          {
            NAVIGATION.map(({ key, label, path, icon: Icon, shortcut }) => (
              <li key={key}>
                <NavLink
                  location={location}
                  to={path}
                  activeClassName="active">
                  {Icon && (<span className="icon-wrapper"><Icon /></span>)}
                  <span className="label">{label}</span>
                  <QuickAction
                    label={label}
                    context="global"
                    shortcut={shortcut}
                    handler={() => history.push(path)} />
                </NavLink>
              </li>
            ))
          }
        </ul>

        <ul className="util">
          <li>
            <a onClick={logout}>
              <span className="icon-wrapper"><LogoutIcon /></span>
              <span className="label">Logout</span>
            </a>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default withRouter(AppNav);
