'use strict';

import {
  REHYDRATE_CLIENT_SETTINGS,
  REHYDRATE_GLOBAL_SETTINGS,
  UPDATE_CLIENT_SETTING,
  UPDATE_GLOBAL_SETTING
} from 'actions';

const initialState = {
  client_settings: {
    landing_page: `/sites`,
    disconnected_message_hidden: false,
    favorite_sites: []
  },
  global_settings: {
    time_zone: `America/Chicago`,
    theme: `system`,
    page_size: 25,
    notifications_shown: false,
    prefer_manual_qr_entry: false,
    gordon_client_id: `ee646194-a2fd-443e-8bad-a48d4338f5d2`
  }
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE_CLIENT_SETTINGS:
      return {
        ...state,
        client_settings: {
          ...initialState.client_settings,
          ...state.client_settings,
          ...action.payload.client_settings
        }
      };
    case REHYDRATE_GLOBAL_SETTINGS:
      return {
        ...state,
        global_settings: {
          ...initialState.global_settings,
          ...state.global_settings,
          ...action.payload.global_settings
        }
      };
    case UPDATE_CLIENT_SETTING:
      return {
        ...state,
        client_settings: {
          ...state.client_settings,
          [action.key]: action.payload
        }
      };
    case UPDATE_GLOBAL_SETTING:
      return {
        ...state,
        global_settings: {
          ...state.global_settings,
          [action.key]: action.payload
        }
      };
    default:
      return state;
  }
}
