'use strict';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import auth from './auth.js';
import settings from './settings.js';

const persist = (name, reducer, config = { }) => persistReducer({
  keyPrefix: `gordon->v1->`,
  key: name,
  storage,
  ...config
}, reducer);

export default combineReducers({
  auth: persist(`auth`, auth),
  settings: persist(`settings`, settings)
});
