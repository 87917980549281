'use strict';

import React from 'react';

import 'scss/utilities/route-root.scss';

const RouteRoot = ({ className, children }) => (
  <div className={ `route-root ${ className ? className : `` }` }>
    { children }
  </div>
);

export default RouteRoot;
