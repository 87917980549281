'use strict';

import React from 'react';
import App from 'components/App';
import { BrowserRouter } from 'react-router-dom';

import { ChariotProvider } from '@fsg/chariot.js/adaptors/react';
import { ThemeProvider } from '@fsg/spokes';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'helpers/ReduxStore.js';

import LoadingRoute from 'components/utilities/LoadingRoute';

const Root = () => {
  return (
    <ChariotProvider domain="aeon.fsgchariot.app" cache cors>
      <ThemeProvider theme="gordon">
        <Provider store={ store }>
          <PersistGate
            loading={ <LoadingRoute /> }
            persistor={ persistor }>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ChariotProvider>
  );
};

export default Root;
