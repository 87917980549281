'use strict';

import React from 'react';
import { AppError, AppMessage } from '@fsg/spokes';

import 'scss/utilities/error-catcher.scss';

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    console.error(error);
    this.setState({ error });
  }

  render() {
    const { error } = this.state;
    if (!error) return this.props.children;

    let renderedError = error;
    
    if (error.message) renderedError = error.message;

    return (
      <div className="error-catcher">
        <AppMessage>
          An error occurred while rendering the requested data. Usually errors are able to be resolved by refreshing the page. If you continue encountering issues, please contact the system administrator.
        </AppMessage>
        <AppError>
          { renderedError }
        </AppError>
      </div>
    );
  }
}

export default ErrorCatcher;
