'use strict';

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronsLeft from 'svgs/chevrons-left.svg';
import { setPageTitle } from 'helpers/TitleHelper';
import ClientSwitcher from 'components/utilities/ClientSwitcher';

import 'scss/utilities/route-header.scss';

const RouteHeader = ({ breadcrumb, pageTitle, additionalActions, children, showClientSelector, hideClientSelector, disableClientSelector }) => {
  const [title, setTitle] = useState(``);

  useEffect(() => {
    setPageTitle(pageTitle || title);

    return () => setPageTitle();
  }, [title, pageTitle]);

  return (
    <header className="route-header">
      <div className="route-header-global">
        <div className="page-title">
          {
            breadcrumb && breadcrumb.length > 0 && (
              <div className="breadcrumb">
                {
                  breadcrumb.map(({ label, path }, index) => (
                    <Link key={ `${ path }-${ index }` } to={ path }>
                      <ChevronsLeft />
                      <span>{ label }</span>
                    </Link>
                  ))
                }
              </div>
            )
          }
            
          <h1 ref={ (n) => n && n.innerText.trim() !== title && setTitle(n.innerText.trim()) }>
            { children }
          </h1>
        </div>

        { 
          additionalActions && (
            <div className="additional-actions">
              { additionalActions }
            </div>
          )
        }
        {
          (!hideClientSelector && (showClientSelector || (!breadcrumb || breadcrumb.length == 0))) && (
            <ClientSwitcher disabled={ disableClientSelector } />
          )
        }
      </div>
    </header>
  );
};

export default RouteHeader;
