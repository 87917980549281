'use strict';

import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthenticatedPageWrapper from 'components/AuthenticatedPageWrapper';
import UnauthenticatedPageWrapper from 'components/UnauthenticatedPageWrapper';
import LoadingRoute from 'components/utilities/LoadingRoute';
import LoadingUnauthenticatedRoute from 'components/utilities/LoadingUnauthenticatedRoute';
import ErrorCatcher from 'components/utilities/ErrorCatcher';
import { getQueryParam, stringifyParams } from 'helpers/QueryHelper';
import { setAuthState, resetAuthState } from 'actions/auth';
import { rehydrateClientSettings, rehydrateGlobalSettings } from 'actions/settings';
import { useAppNotification } from '@fsg/spokes';
import { useChariot } from '@fsg/chariot.js/adaptors/react/hooks';
import store from '../helpers/ReduxStore';

import 'scss/app.scss';

const App = () => {
  const dispatch = useDispatch();
  const chariot = useChariot();
  const notify = useAppNotification();
  const auth = useSelector((state) => state.auth);
  const [rehydrated, setRehydrated] = useState(false);

  useEffect(() => {
    (async () => {
      await chariot.waitForLogin();
      await chariot.getUserSettings();
    })();
  }, []);

  useEffect(() => {
    chariot.auth.onAuthUpdated.subscribe(async (authState) => {
      try {
        if (+new Date(authState.expires_at) < Date.now() && authState.refresh_token) await chariot.refreshLogin({ refresh_token: authState.refresh_token });
      } catch (e) {
        dispatch(resetAuthState());
      }
    });

    chariot.onLoginStateChanged.subscribe(async (loginState) => {
      if (loginState && loginState.user) {
        let local_client_id = store.getState().settings?.global_settings?.gordon_client_id;

        if (!local_client_id) {
          local_client_id = `ee646194-a2fd-443e-8bad-a48d4338f5d2`;
          await chariot.updateUserSettings({ global_settings: { gordon_client_id: local_client_id } });
        }

        const client = await chariot.Client.get(local_client_id);

        chariot.loginState.client = client._;
        chariot.options.domain = `${ client._.domain }.fsgchariot.app`;

        setRehydrated(true);
        dispatch(setAuthState(chariot.auth.getState()));
      }
    });

    chariot.onGlobalSettingsChanged.subscribe((globalSettings) => {
      dispatch(rehydrateGlobalSettings(globalSettings));
    });

    chariot.onClientSettingsChanged.subscribe((clientSettings) => {
      dispatch(rehydrateClientSettings(clientSettings));
    });

    chariot.logger.onLog.subscribe(({ level, message }) => {
      const type = level == `error` ? `error` : `warning`;

      notify({
        type,
        message
      });
    });
  }, []);

  useEffect(() => {
    if (chariot && JSON.stringify(chariot.auth.getState()) !== JSON.stringify(auth)) {
      chariot.auth.setState(auth);
    }
  }, [JSON.stringify(auth)]);

  const redirectPath = getQueryParam(`r`),
    redirectParam = stringifyParams({
      r: `${ window.location.pathname }${ window.location.search }`
    });

  return (
    <ErrorCatcher>
      <main>
        {
          /* the order of the Suspense and PageWrappers are intentionally different */
          !auth.authenticated ? (
            <Suspense fallback={<LoadingUnauthenticatedRoute />}>
              <UnauthenticatedPageWrapper key="unauthenticated-page-wrapper">
                <Switch>
                  <Route path="/login" component={lazy(() => import(`components/login/LoginRouter.jsx`))} />
                  <Redirect from="*" to={`/login${ redirectParam }`} />
                </Switch>
              </UnauthenticatedPageWrapper>
            </Suspense>
          ) : rehydrated ? (
            <AuthenticatedPageWrapper key="authenticated-page-wrapper">
              <ErrorCatcher>
                <Suspense fallback={<LoadingRoute />}>
                  <Switch>
                    <Route path="/clients" component={lazy(() => import(`components/clients/ClientsRouter.jsx`))} />
                    <Route path="/sites" component={lazy(() => import(`components/sites/SitesRouter.jsx`))} />
                    <Route path="/users" component={lazy(() => import(`components/users/UsersRouter.jsx`))} />
                    <Route path="/scan" component={lazy(() => import(`components/scan/ScanToolRouter.jsx`))} />
                    <Route path="/scripts" component={lazy(() => import(`components/scripts/ScriptsRouter.jsx`))} />
                    <Route path="/templates" component={lazy(() => import(`components/templates/TemplatesRouter.jsx`))} />
                    <Route path="/niagara" component={lazy(() => import(`components/niagara/NiagaraRouter.jsx`))} />
                    <Route path="/service-accounts" component={lazy(() => import(`components/service-accounts/ServiceAccountsRouter.jsx`))} />
                    <Route path="/vpam" component={lazy(() => import(`components/vpam/VpamRouter.jsx`))} />
                    {
                      redirectPath && (
                        <Redirect from="*" to={redirectPath} />
                      )
                    }
                    <Redirect exact from="/login" to="/sites" />
                    <Redirect exact from="/" to="/sites" />
                    <Route path="*" component={lazy(() => import(`components/PageNotFound.jsx`))} />
                  </Switch>
                </Suspense>
              </ErrorCatcher>
            </AuthenticatedPageWrapper>
          ) : (
            <LoadingUnauthenticatedRoute />
          )
        }
      </main>
    </ErrorCatcher>
  );
};

export default App;
