'use strict';

import React from 'react';

import 'scss/utilities/route-container.scss';

const RouteContainer = ({ className, children }) => (
  <div className={ `route-container ${ className ? className : `` }` }>
    { children }
  </div>
);

export default RouteContainer;
