'use strict';

/* Auth */
export const RESET_AUTH_STATE = `AUTH/RESET_STATE`;
export const SET_AUTH_STATE = `AUTH/SET_STATE`;

/* Settings */
export const REHYDRATE_CLIENT_SETTINGS = `SETTINGS/REHYDRATE_CLIENT`;
export const UPDATE_CLIENT_SETTING = `SETTINGS/UPDATE_CLIENT_SETTING`;
export const REHYDRATE_GLOBAL_SETTINGS = `SETTINGS/REHYDRATE_GLOBAL`;
export const UPDATE_GLOBAL_SETTING = `SETTINGS/UPDATE_GLOBAL_SETTING`;
